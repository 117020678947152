import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-find-out-more-square',
  templateUrl: './find-out-more-square.component.html',
  styleUrls: ['./find-out-more-square.component.scss']
})
export class FindOutMoreSquareComponent implements OnInit {

  @Input() imageUrl: string;
  @Input() title: string;
  @Input() content: string;
  @Input() linkArray: string[];
  @Input() button: boolean;

  firefoxDisplay: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (this.button !== false) this.button = true;
    this.firefoxDisplay = window.navigator.userAgent.toLowerCase().includes("firefox");
  }

}
