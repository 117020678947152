<div class="wrapper">
    <div class="content-wrapper">
        <img src="/assets/logos/Orange_Technology_Ltd/White/OTL-logo-2020-white.png" [routerLink]="['/']" class="logo" />
        <div class="nav-wrapper">
            <a href="" [routerLink]="['/hospitality']" class="nav-anchor">
                <p class="nav-link">Hospitality</p>
            </a>
            <a href="" [routerLink]="['/corporate']" class="nav-anchor">
                <p class="nav-link">Corporate</p>
            </a>
            <a href="" [routerLink]="['/construction']" class="nav-anchor">
                <p class="nav-link">Construction</p>
            </a>
            <div class="nav-anchor dropdown-nav-anchor">
                <p class="nav-link">Tools <i class="ti ti-angle-down" style="font-size: 0.9rem;"></i></p>
                <div class="nav-dropdown">
                    <a href="" [routerLink]="['/tools', 'business-simulations']" class="nav-child-anchor">
                        <p class="nav-child-link">Business Simulations</p>
                    </a>
                    <a href="" [routerLink]="['/tools', 'evoucherz']" class="nav-child-anchor">
                        <p class="nav-child-link">Food For Volunteers</p>
                    </a>
                    <a href="" [routerLink]="['/tools', 'cleaning-management-and-monitoring']" class="nav-child-anchor">
                        <p class="nav-child-link">Cleaning Management and Monitoring</p>
                    </a>
                    <a href="" [routerLink]="['/tools', 'employee-relationship-gateway']" class="nav-child-anchor">
                        <p class="nav-child-link">Employee Relationship Gateway</p>
                    </a>
                </div>
            </div>
        </div>
        <div class="nav-wrapper-mobile">
            <div class="nav-mobile-icon" (click)="openMobileDropdown()">
                <div class="nav-mobile-icon-line"></div>
                <div class="nav-mobile-icon-line"></div>
                <div class="nav-mobile-icon-line"></div>
            </div>
            <div class="mobile-dropdown nav-dropdown">
                <a href="" [routerLink]="['/hospitality']" class="mobile-nav-anchor nav-child-anchor">
                    <p class="mobile-nav-link nav-child-link">Hospitality</p>
                </a>
                <a href="" [routerLink]="['/corporate']" class="mobile-nav-anchor nav-child-anchor">
                    <p class="mobile-nav-link nav-child-link">Corporate</p>
                </a>
                <a href="" [routerLink]="['/construction']" class="mobile-nav-anchor nav-child-anchor">
                    <p class="mobile-nav-link nav-child-link">Construction</p>
                </a>
                <div class="mobile-nav-anchor mobile-dropdown-nav-anchor">
                    <p class="mobile-nav-link nav-child-link">Tools <i class="ti ti-angle-down" style="font-size: 0.9rem;"></i></p>
                    <div class="mobile-nav-dropdown">
                        <a href="" [routerLink]="['/tools', 'benchmarking']" class="mobile-nav-child-anchor">
                            <p class="mobile-nav-child-link">Benchmarking</p>
                        </a>
                        <a href="" [routerLink]="['/tools', 'business-simulations']" class="mobile-nav-child-anchor">
                            <p class="mobile-nav-child-link">Business Simulations</p>
                        </a>
                        <a href="" [routerLink]="['/tools', 'cleaning-management-and-monitoring']" class="mobile-nav-child-anchor">
                            <p class="mobile-nav-child-link">Cleaning Management and Monitoring</p>
                        </a>
                        <a href="" [routerLink]="['/tools', 'employee-relationship-gateway']" class="mobile-nav-child-anchor">
                            <p class="mobile-nav-child-link">Employee Relationship Gateway</p>
                        </a>
                        <a href="" [routerLink]="['/tools', 'juice']" class="mobile-nav-child-anchor">
                            <p class="mobile-nav-child-link">Juice</p>
                        </a>
                        <a href="" [routerLink]="['/tools', 'three-sixty-feedback']" class="mobile-nav-child-anchor">
                            <p class="mobile-nav-child-link nav-child-link-bottom">360⁰ Feedback</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>