<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">

<h1 class="title" style="width: 600px;">COMING SOON<br />
	<img src="/assets/new-images/kleencheck-content-logo.png" alt="KleenCheck" style="width: 50%; margin-bottom: 5px;" /><br />
	a new generation of our cleaning Apps</h1>
    <div class="row">
        <div class="col-lg-6">
            
            <img src="/assets/new-images/KleenCheckPro.jpg" class="KleenCheck Pro" style="width: 100%;" />
        </div>
        <div class="col-lg-6">
            <img src="/assets/new-images/KleenCheckPlus.jpg" class="KleenCheck Plus" style="width: 100%;" />
        </div>
    </div>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>