<div class="content-wrapper">
    <h1 class="title">Orange Technology Ltd</h1>
    <h5>Integrated personalised online Onboarding, Induction, Training and Development programmes to help your people be the best they can be</h5>
    <div class="centering">
        <app-find-out-more-square
            imageUrl="/assets/new-images/hospitality-icon.png"
            title="Hospitality"
            content="Dealing with high numbers of staff, full time and variable hours."
            [linkArray]="['/hospitality']"
        ></app-find-out-more-square>
        <app-find-out-more-square
            imageUrl="/assets/new-images/corporate-icon.png"
            title="Corporate"
            content="Full tailoring to Divisions, Roles, Locations."
            [linkArray]="['/corporate']"
        ></app-find-out-more-square>
        <app-find-out-more-square
            imageUrl="/assets/new-images/construction-icon.png"
            title="Construction"
            content="H&S Inductions designed for each site."
            [linkArray]="['/construction']"
        ></app-find-out-more-square>
    </div>
    <p>
        Our services accompany employees throughout their journey within a workplace.
        From onboarding via a pre-arrival induction and selection testing, the first month of transitioning into their new role,
        onto continuous development and training using our simulations and 360 feedback tool.
    </p>
    <p>
        Delivering information to large numbers of people that is tailored to the individual at a time that is convenient for the user,
        whilst also gathering information in a level of interactivity that is only available via an online service.
    </p>
    <p>
        By identifying customer needs we have a flexibility to adapt our existing services to meet those needs or to provide a bespoke solution.
    </p>
</div>