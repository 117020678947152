<div class="row">
    <div class="col-md-6" *ngIf="imageSide == 'left' ">
        <div class="image-wrapper" [style.backgroundImage]="'url(' + imageUrl + ')'"></div>
    </div>
    <div class="col-md-6">
        <ng-content></ng-content>
    </div>
    <div class="col-md-6" *ngIf="imageSide == 'right' ">
        <div class="image-wrapper" [style.backgroundImage]="'url(' + imageUrl + ')'"></div>
    </div>
</div>