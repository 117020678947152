<app-header
    title="HOW IT WORKS"
    subtitle="Established 1995 focusing around induction, communication and people development"
></app-header>

<div class="content-wrapper content-min-height">
    <h1 class="title">How it works</h1>
    <p>
        We provide solutions to a variety of communication, training and operational needs. 
    </p>
    <p>
        For communication and training projects we also provide a full content management service. 
    </p>
    <p>
        Most projects include a bespoke element, we therefore always welcome a no commitment initial discussion of the issue you are confronting.
        All of our services are provided as fully hosted solutions.
    </p>
    <p>
        Our communication platform embraces: assistance with Recruitment, Onboarding, Induction, Ongoing Training, Compliance Refresher Training,
        Employee News and organisational Restructuring Communication. 
    </p>
    <p>
        Complete flexibility allows any element or combination of elements to be utilised initially, with any of the others being able to be added
        seamlessly at any time. Totally new modules can be developed and added as required. This approach allows organisations to set a development
        plan in line with their needs and the availability of internal resources. It also reduces the perceived risk in committing to the project
        at the initial development stage.
    </p>
    <p>
        Our operational software works the same way, for example our new Covid Cleaning Routine Management App can be extended to cover: Fire Inspections,
        Health & safety Inspections, Room Condition or Area condition inspections and other activities.
    </p>
    <p>
        If you have a need, no matter how big or small, how complex or simple, how novel or entrenched – Contact us.
    </p>
</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>