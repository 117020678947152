<app-header
    title="OPERATIONAL TOOLS"
    subtitle="Enhance performance with our bespoke online systems"
></app-header>

<div class="content-wrapper content-min-height">
    <div class="row">
        <div class="col-lg-6">
            <h1 class="title">FOOD FOR VOLUNTEERS</h1>
            <p style="font-size: 1.5em; color: #fab31e;">
                Say goodbye to paper vouchers, lengthy queues, and disappointed workers.
            </p>
            <p style="font-size: 1.5em;">
                <a href="https://e-voucherz.com/" target=_blank>E-Voucherz</a>
            </p>
        </div>
        <div class="col-lg-6">
            <a href="https://e-voucherz.com/" target=_blank><img src="assets/new-images/voucherz-logo.png" alt="E-Voucherz Logo" /></a>
        </div>
    </div>

</div>

<div class="spacing-element" style="height: 50px;"></div>

<app-footer></app-footer>