import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-our-tools',
  templateUrl: './our-tools.component.html',
  styleUrls: ['./our-tools.component.scss']
})
export class OurToolsComponent implements OnInit {

  constructor(private router: Router) {
    window.onresize = this.setScreenWidth.bind(this);
  }

  ngOnInit(): void {
    this.setScreenWidth();
  }

  // This is used to repeat the elements in the slider
  sliderLoopArray: number[] = Array(8).fill(0);

  screenWidth: number;
  mouseDownX: number = null;

  setScreenWidth(): void {
    this.screenWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
  }

  caseStudyClicked(event: any, linkArray: string[], mouseDown: boolean): void {
    if (mouseDown) {
      this.mouseDownX = event.pageX;
    } else {
      let dragDistance = Math.abs(this.mouseDownX - event.pageX);
      if (dragDistance <= 10) this.router.navigate(linkArray);
    }
  }

}
