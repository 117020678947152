import { Injectable } from '@angular/core';
import { CaseStudy } from '../models/case-study.class';

@Injectable({
  providedIn: 'root'
})
export class CaseStudiesService {

  caseStudies: CaseStudy[] = [
    {
      reference: "delaware-north",
      title: "New Employee Induction and Selection programme for Delaware North",
      contentPreview: "Recruiting a high number of new staff (both permanent and temporary) on a regular basis Delaware North were looking for a cost effective solution to inducting large quantities of new employees whilst also being able to test their knowledge of key areas such as health and safety",
      content: `Recruiting a high number of new staff (both permanent and temporary) on a regular basis Delaware North were looking for a cost-effective solution to induct these new employees, whilst also being able to assess their knowledge of key areas relevant to their new roles.
      Working with the HR team, a two-stage selection and induction programme was developed. Stage one delivered important information including topics such as Health and Safety and Food Hygiene concluding in a set of test questions. The results are then used to decide whether a candidate continues with the recruitment process. Once appointed as an employee by Delaware North they automatically continue onto their induction process (stage two). `,
      caseStudyImageUrl: "/assets/images/dn-screen.jpg",
      clientLogoUrl: "/assets/images/dn.jpeg",
      caseStudyType: "corporate"
    },
    {
      reference: "ascot",
      title: "Induction programme for catering contractors, security contractors and specialist roles",
      contentPreview: "Ascot racecourse has a high reputation with the Royal Ascot event in particulate highly regarded as the centrepiece. the values set out in \"The Ascot way\" can be seen in every aspect of service delivery, from welcoming visitors to serving the perfect pint.",
      content: `Ascot racecourse has a high reputation with the Royal Ascot event in particular highly regarded as the centrepiece. The values set out in “The Ascot Way” can be seen in every aspect of service delivery, from welcoming visitors to serving the perfect pint.
      It was very important to communicate these values and other information about the racecourse to all employees working there at any time, including staff that are provided by contractors.
      A dual site induction programme was developed, signifying the difference between Royal Ascot and the other events conducted at Ascot racecourse. Using lots of interactivity, such as videos and drag and drop functionality, key information is delivered and knowledge is assessed through a number of questions.`,
      caseStudyImageUrl: "/assets/images/ascot-screen.jpg",
      clientLogoUrl: "/assets/images/ascot.jpg",
      caseStudyType: "hospitality"
    },
    {
      reference: "burhill-golf-and-leisure",
      title: "Employee Induction for Burhill Golf and Leisure",
      contentPreview: "The Burhill Group Limited own and manage multiple sites across three divisions; Golf, Leisure & Estates. It was important to deliver consistent messages across the Group whjilst also tailoring to specific division and site information.",
      content: `The Burhill Group Limited own and manage multiple sites across three divisions; Golf, Leisure & Estates. It was important to deliver consistent messages across the Group whilst also tailoring to specific division and site information. Using our Multi-Tagging™ functionality we are able to deliver this and even deliver job specific information such as “Service of Alcohol”.
      BGL also saw the opportunity to expand their onboarding process and streamline administrative tasks associated with recruitment by including forms for payroll and personal information, and uniforms within their induction programme.`,
      caseStudyImageUrl: "/assets/images/bgl-screen.jpg",
      clientLogoUrl: "/assets/images/bgl.png",
      caseStudyType: "construction"
    },
    {
      reference: "university-of-sheffield",
      title: "Staff Induction at the University of Sheffield",
      contentPreview: "The University of Sheffield employs a range of staff from cleaner to professor, across 51 academic and 15 professional services departments. Howver, despite a strong employer brand from a recruitment perspective, it felt it was missing an opportunity to demonstrate its goals and values during induction.",
      content: `The University of Sheffield employs a range of staff from cleaner to professor, across 51 academic and 15 professional services departments. However, despite a strong employer brand from a recruitment perspective, it felt it was missing an opportunity to demonstrate its goals and values during induction. This was made more complex thanks to the fact that 250 staff across the institution are responsible for induction, many of whom were unaware of the resources available to support new starters.
      In partnership with our sister company STEMS by RP we developed a branded induction portal for all new staff regardless of role at the university. Since implementation of the staff induction portal the University of Sheffield has found that the number of staff resigning within three months of joining has dropped by 36% with managers stating that the induction programme enabled them to provide a more personal welcoming experience.`,
      caseStudyImageUrl: "/assets/images/sheffield-screen.jpg",
      clientLogoUrl: "/assets/images/sheffield.jpg",
      caseStudyType: "corporate"
    }
  ];

}
