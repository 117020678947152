<div class="wrapper">
    <div class="content-wrapper">
        <div class="row">
            <div class="col-sm-6">
                <p>Copyright @ 2021 Orange Technology Ltd.</p>
            </div>
            <div class="col-sm-6 right-col">
                <a href="" [routerLink]="['/terms-and-conditions']">Website Terms & Conditions</a>
                <div class="divider"></div>
                <a href="" [routerLink]="['/privacy-gdpr']">Privacy</a>
                <div class="divider"></div>
                <a href="" [routerLink]="['/cookies']">Cookies</a>
		<div class="divider"></div>
		<a href="https://orangetechnology.co.uk/Anti%20Slavery%20and%20Human%20trafficking.pdf" target=_blank>Modern Slavery Statement</a>
		<div class="divider"></div>
		<a href="https://orangetechnology.co.uk/OTL%20Carbon%20reduction%20Plan%202024.pdf" target=_blank>CRP</a>
            </div>
        </div>
    </div>
</div>