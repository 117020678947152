<div class="wrapper">
    <div class="content-wrapper">
        <div class="row">

            <div class="col-lg-3">
                <h6>Orange Technology</h6>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/about-us']"><p class="footer-link-text">About us</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/how-it-works']"><p class="footer-link-text">How it works</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/case-studies']"><p class="footer-link-text">Case Studies</p></a>
                </div>
            </div>

            <div class="col-lg-3">
                <h6>Sectors</h6>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/hospitality']"><p class="footer-link-text">Hospitality</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/corporate']"><p class="footer-link-text">Corporate</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/construction']"><p class="footer-link-text">Construction</p></a>
                </div>
                <!--<div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/education']"><p class="footer-link-text">Education</p></a>
                </div>-->
            </div>

            <div class="col-lg-3">
                <h6>Tools</h6>
		<div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/tools', 'business-simulations']"><p class="footer-link-text">Business Simulations</p></a>
                </div>
		<div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/tools', 'evoucherz']"><p class="footer-link-text">Food For Volunteers</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/tools', 'cleaning-management-and-monitoring']"><p class="footer-link-text">Cleaning Management and Monitoring</p></a>
                </div>
                <div>
                    <i class="ti ti-angle-right bullet"></i>
                    <a href="" [routerLink]="['/tools', 'employee-relationship-gateway']"><p class="footer-link-text">Employee Relationship Gateway</p></a>
                </div>
            </div>

            <div class="col-lg-3">
                <h6>Contact</h6>
                <div><p>Basepoint</p></div>
                <div><p>Aviation Business Park</p></div>
                <div><p>Christchurch</p></div>
                <div><p>Dorset, BH23 6NX</p></div>
                <div><p>info@orangetechnology.co.uk</p></div>
            </div>
        </div>
    </div>
</div>