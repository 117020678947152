import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Pages
import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { HowItWorksComponent } from './pages/how-it-works/how-it-works.component';
import { BespokeComponent } from './pages/bespoke/bespoke.component';
import { CaseStudiesComponent } from './pages/case-studies/case-studies.component';
import { HospitalityComponent } from './pages/hospitality/hospitality.component';
import { CorporateComponent } from './pages/corporate/corporate.component';
import { ConstructionComponent } from './pages/construction/construction.component';
import { EducationComponent } from './pages/education/education.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyGdprComponent } from './pages/privacy-gdpr/privacy-gdpr.component';
import { AvailabilityBookerComponent } from './pages/tools/availability-booker/availability-booker.component';
import { OrangeCommunicationsPortalComponent } from './pages/tools/orange-communications-portal/orange-communications-portal.component';
import { JuiceComponent } from './pages/tools/juice/juice.component';
import { ChangecomComponent } from './pages/tools/changecom/changecom.component';
import { ThreeSixtyFeedbackComponent } from './pages/tools/three-sixty-feedback/three-sixty-feedback.component';
import { ToolsComponent } from './pages/tools/tools/tools.component';
import { CaseStudyComponent } from './pages/case-study/case-study.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { EmployeeRelationshipGatewayComponent } from './pages/tools/employee-relationship-gateway/employee-relationship-gateway.component';
import { CleaningManagementAndMonitoringComponent } from './pages/tools/cleaning-management-and-monitoring/cleaning-management-and-monitoring.component';
import { BenchmarkingComponent } from './pages/tools/benchmarking/benchmarking.component';
import { BusinessSimulationsComponent } from './pages/tools/business-simulations/business-simulations.component';
import { EvoucherzComponent } from './pages/tools/evoucherz/evoucherz.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'bespoke', component: BespokeComponent },
  { path: 'case-studies', component: CaseStudiesComponent },
  { path: 'case-studies/:page', component: CaseStudiesComponent },
  { path: 'case-study/:caseStudyReference', component: CaseStudyComponent },
  { path: 'hospitality', component: HospitalityComponent },
  { path: 'corporate', component: CorporateComponent },
  { path: 'construction', component: ConstructionComponent },
  { path: 'education', component: EducationComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'cookies', component: CookiesComponent },
  { path: 'privacy-gdpr', component: PrivacyGdprComponent },
  { path: 'tools/employee-relationship-gateway', component: EmployeeRelationshipGatewayComponent },
  { path: 'tools/cleaning-management-and-monitoring', component: CleaningManagementAndMonitoringComponent },
  { path: 'tools/benchmarking', component: BenchmarkingComponent },
  { path: 'tools/juice', component: JuiceComponent },
  { path: 'tools/three-sixty-feedback', component: ThreeSixtyFeedbackComponent },
  { path: 'tools/business-simulations', component: BusinessSimulationsComponent },
  { path: 'tools/evoucherz', component: EvoucherzComponent },
  { path: 'tools', component: ToolsComponent },
  { path: 'case-study',   redirectTo: '/case-studies', pathMatch: 'full' } // This is required to ensure breadcrumbs work on "case-study/:caseStudyReference" route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
